<template>
	<v-row justify="center">
		<v-dialog v-model="ShowModal" :persistent="persistent" max-width="500">
			<v-card>
				<v-card-title class="headline">{{ Header }}</v-card-title>
				<v-card-text>
					<span v-if="Message != 'You need to set the `Message` prop'">
						{{ Message }}
					</span>
					<slot v-else />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						:color="BtnColor"
						:disabled="BtnDisabled"
						depressed
						dark
						@click="CloseResult()"
					>
						{{ CloseText }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
/**
 * This is a simple message with just one button, to convey quick messages to the user.
 *
 * **BE SURE TO HANDLE THE Close EVENT BY SETTING THE DISPLAY VARIABLE TO FALSE**
 * @displayName Popup Alert
 */

export default {
	name: "AlertModal",

	props: {
		/**
		 * Prop for v-model
		 * @values true, false
		 */
		value: {
			default: false,
		},
		/**
		 * Controls if the modal is shown or not
		 * @values true, false
		 */
		Display: {
			default: false,
		},
		/**
		 * Popup header giving a breif description
		 * @values any string
		 */
		Header: { default: "You need to set the `Header` prop" },
		/**
		 * Message content, typically a paragraph
		 * @values any string
		 */
		Message: { default: "You need to set the `Message` prop" },
		/**
		 * This is the close button text
		 * @values any string
		 */

		CloseText: { default: "Okay" },
		/**
		 * This is the color of the close button
		 * @values primary, secondary, accent, error, info, success, warning
		 */
		BtnColor: { default: "primary" },
		BtnDisabled: { default: false },
		persistent: { default: true },
	},
	data() {
		return {
			ShowModal: false,
		};
	},
	mounted() {
		this.ShowModal = this.value || this.Display;
	},
	watch: {
		Display(v) {
			this.ShowModal = v;
		},
		value(v) {
			this.ShowModal = v;
		},
	},
	methods: {
		CloseResult() {
			this.Display = false;
			/**
			 * Success event, closes the popup and alerts to the closure
			 *
			 * @event Close
			 * @type void
			 */

			this.$emit("Close");
			this.$emit("input", false);
		},
	},
};
</script>

<style></style>

<docs>
```vue
let show = false

<v-btn @click="show=!show" color="primary">Open</v-btn>
<PopupAlert
  :Display="show"
  @Close="show=false"
  Message="Hello, this is a test."
  Header="Test!" BtnColor="secondary"
  CloseText="Good Test!"
/>
```
</docs>
